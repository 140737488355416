var render = function render(){
  var _vm$otherSessionId, _vm$activeFeedback2, _vm$activeFeedback2$u, _vm$activeFeedback3, _vm$activeFeedback3$u, _vm$otherSessionId2, _vm$otherSessionId3, _vm$attachments;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.activeFeedback ? _c('v-card', {
    staticClass: "feedback-chat",
    attrs: {
      "outlined": "",
      "rounded": ""
    }
  }, [_c('div', {
    staticClass: "feedback-chat__header"
  }, [_c('div', {
    staticClass: "feedback-chat__title"
  }, [_c('span', [_vm._v("Feedback #" + _vm._s(_vm.activeFeedback.id))])]), _c('div', {
    staticClass: "data-simplebar",
    class: ((_vm$otherSessionId = _vm.otherSessionId) === null || _vm$otherSessionId === void 0 ? void 0 : _vm$otherSessionId.length) > 0 ? 'feedbackHeader' : 'feedback-chat__header-chips'
  }, [_c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref) {
        var _vm$activeFeedback, _vm$activeFeedback$us;
        var on = _ref.on;
        return [_c('v-chip', _vm._g({}, on), [_c('span', {
          staticClass: "font-medium"
        }, [_vm._v(_vm._s((_vm$activeFeedback = _vm.activeFeedback) === null || _vm$activeFeedback === void 0 ? void 0 : (_vm$activeFeedback$us = _vm$activeFeedback.user) === null || _vm$activeFeedback$us === void 0 ? void 0 : _vm$activeFeedback$us.identity))])])];
      }
    }], null, false, 1881007846)
  }, [_c('span', [_vm._v(" User Identity ")])]), (_vm$activeFeedback2 = _vm.activeFeedback) !== null && _vm$activeFeedback2 !== void 0 && (_vm$activeFeedback2$u = _vm$activeFeedback2.user) !== null && _vm$activeFeedback2$u !== void 0 && _vm$activeFeedback2$u.name ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref2) {
        var on = _ref2.on;
        return [_c('v-chip', _vm._g({
          staticClass: "mx-2"
        }, on), [_c('span', {
          staticClass: "font-medium"
        }, [_vm._v(_vm._s(_vm.activeFeedback.user.name))])])];
      }
    }], null, false, 2235560849)
  }, [_c('span', [_vm._v(" User name ")])]) : _vm._e(), (_vm$activeFeedback3 = _vm.activeFeedback) !== null && _vm$activeFeedback3 !== void 0 && (_vm$activeFeedback3$u = _vm$activeFeedback3.user) !== null && _vm$activeFeedback3$u !== void 0 && _vm$activeFeedback3$u.email ? _c('v-tooltip', {
    attrs: {
      "bottom": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref3) {
        var on = _ref3.on;
        return [_c('v-chip', _vm._g({
          staticClass: "mx-2"
        }, on), [_c('span', {
          staticClass: "font-medium"
        }, [_vm._v(_vm._s(_vm.activeFeedback.user.email))])])];
      }
    }], null, false, 3143947418)
  }, [_c('span', [_vm._v(" User email ")])]) : _vm._e(), _vm.activeFeedback.status === 1 ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "color": "#fff5dd",
      "text-color": "orange"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "orange",
      "left": ""
    }
  }, [_vm._v("mdi-clock-outline")]), _c('span', {
    staticClass: "black--text font-medium"
  }, [_vm._v("Not resolved")])], 1) : _vm._e(), _vm.activeFeedback.status === 2 ? _c('v-chip', {
    staticClass: "mx-2",
    attrs: {
      "color": "#e0ffe1",
      "text-color": "green"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "green",
      "left": ""
    }
  }, [_vm._v("mdi-check-circle-outline")]), _c('span', {
    staticClass: "black--text font-medium"
  }, [_vm._v("Resolved")])], 1) : _vm._e(), (_vm$otherSessionId2 = _vm.otherSessionId) !== null && _vm$otherSessionId2 !== void 0 && _vm$otherSessionId2.length ? [_vm._l(_vm.otherSessionId, function (id, index) {
    return [_c('v-tooltip', {
      key: index,
      attrs: {
        "bottom": ""
      },
      scopedSlots: _vm._u([{
        key: "activator",
        fn: function fn(_ref4) {
          var on = _ref4.on;
          return [_c('v-chip', _vm._g({
            staticClass: "mr-2",
            attrs: {
              "color": "#E3F2FD",
              "text-color": "primary"
            },
            on: {
              "click": function click($event) {
                return _vm.openSession(id);
              }
            }
          }, on), [_c('v-icon', {
            attrs: {
              "color": "primary",
              "left": ""
            }
          }, [_vm._v(" mdi-timeline-text-outline ")]), _vm._v(" " + _vm._s(id) + " ")], 1)];
        }
      }], null, true)
    }, [_c('span', [_vm._v(" Connected Session ")])])];
  })] : _vm._e()], 2), _c('v-spacer'), _c('div', {
    staticClass: "feedback-chat__controls"
  }, [_c('v-menu', {
    attrs: {
      "bottom": "",
      "left": ""
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function fn(_ref5) {
        var on = _ref5.on,
          attrs = _ref5.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-dots-vertical")])], 1)];
      }
    }], null, false, 3221905750)
  }, [_c('v-list', {
    staticStyle: {
      "padding": "0"
    }
  }, [_c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": _vm.copyLink
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-content-copy")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("Copy link ")])], 1), _c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": function click($event) {
        return _vm.changeFeedbackStatus(_vm.activeFeedback.status);
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-check")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" Mark as " + _vm._s(this.activeFeedback.status === 1 ? "Resolved" : "Not Resolved") + " ")])], 1), _c('v-list-item', {
    staticStyle: {
      "padding": "0 16px 0 12px"
    },
    attrs: {
      "ripple": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteFeedbackDialog = true;
      }
    }
  }, [_c('v-icon', {
    attrs: {
      "small": ""
    }
  }, [_vm._v("mdi-delete")]), _c('v-list-item-title', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v(" Delete ")])], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "feedback-chat__body"
  }, [(_vm$otherSessionId3 = _vm.otherSessionId) !== null && _vm$otherSessionId3 !== void 0 && _vm$otherSessionId3.length ? _c('v-alert', {
    attrs: {
      "outlined": "",
      "color": "primary"
    }
  }, [_c('v-icon', {
    attrs: {
      "color": "primary",
      "left": ""
    }
  }, [_vm._v(" mdi-timeline-text-outline ")]), _vm._v(" This feedback is generated from multiple sessions... ")], 1) : _vm._e(), _c('div', {
    staticClass: "feedback-chat__messages"
  }, [_c('div', {
    staticClass: "feedback-chat__message"
  }, [!_vm.attachmentsBusy ? [_c('div', {
    staticClass: "feedback-chat__prepend"
  }, [_c('div', {
    staticClass: "feedback-chat__prepend-img"
  }, [_c('v-icon', {
    staticStyle: {
      "font-size": "20px"
    },
    attrs: {
      "color": "primary"
    }
  }, [_vm._v("mdi-message-reply-text-outline ")])], 1), _c('div', {
    staticClass: "feedback-chat__prepend-stripe"
  })]), _c('div', {
    staticClass: "feedback-chat__attachment"
  }, [_vm.activeFeedback.message ? _c('div', {
    staticClass: "feedback-chat__attachment-message",
    domProps: {
      "innerHTML": _vm._s(_vm.message)
    }
  }) : _c('div', {
    staticClass: "feedback-chat__attachment-message feedback-chat__attachment-message--empty"
  }, [_vm._v(" No message ")]), _c('div', {
    staticClass: "feedback-chat__attachment-date darkGrey--text"
  }, [_c('date-tooltip', {
    attrs: {
      "date": _vm.activeFeedback.created_at
    }
  })], 1), ((_vm$attachments = _vm.attachments) === null || _vm$attachments === void 0 ? void 0 : _vm$attachments.length) > 0 ? _c('div', {
    staticClass: "feedback-chat__attachment-list"
  }, _vm._l(_vm.attachments, function (attachment, index) {
    return _c('feedback-attachment', {
      key: index,
      attrs: {
        "attachment": attachment
      },
      on: {
        "onDelete": _vm.handleDeleteAttachment,
        "onMediaClick": _vm.handleMediaClick
      }
    });
  }), 1) : _vm._e()])] : [_c('div', {
    staticClass: "feedback-chat__attachment-list"
  }, _vm._l(_vm.activeFeedback.stats.attachments, function (index) {
    return _c('feedback-attachment-skeleton', {
      key: index
    });
  }), 1)]], 2)])], 1), _c('v-dialog', {
    attrs: {
      "content-class": "attachment-media-dialog",
      "height": "100%",
      "scrollable": "",
      "width": "auto"
    },
    model: {
      value: _vm.dialog,
      callback: function callback($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_vm.activeMedia && _vm.activeMedia.mime.includes('image') ? _c('img', {
    style: {
      aspectRatio: _vm.activeMedia.width / _vm.activeMedia.height
    },
    attrs: {
      "src": _vm.activeMedia.url,
      "height": "100%"
    }
  }) : _vm._e(), _vm.activeMedia && _vm.activeMedia.mime.includes('video') ? _c('video', {
    style: {
      aspectRatio: _vm.activeMedia.width / _vm.activeMedia.height
    },
    attrs: {
      "src": _vm.activeMedia.url,
      "controls": "",
      "height": "100%"
    }
  }) : _vm._e()]), _c('v-dialog', {
    attrs: {
      "width": "500"
    },
    model: {
      value: _vm.deleteFeedbackDialog,
      callback: function callback($$v) {
        _vm.deleteFeedbackDialog = $$v;
      },
      expression: "deleteFeedbackDialog"
    }
  }, [_c('v-card', [_c('v-card-title', {
    staticClass: "font-size-h3"
  }, [_vm._v("Delete feedback")]), _c('v-divider'), _c('v-card-text', {
    staticClass: "bodyFont p-2"
  }, [_vm._v(" Are you sure you want to delete this feedback? "), _c('v-alert', {
    staticClass: "mt-4",
    attrs: {
      "color": "info",
      "outlined": ""
    }
  }, [_vm._v(" Attachment and feedback will be deleted for other sessions also. ")])], 1), _c('v-divider'), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "color": "primary",
      "text": ""
    },
    on: {
      "click": function click($event) {
        _vm.deleteFeedbackDialog = false;
      }
    }
  }, [_vm._v(" Cancel ")]), _c('v-btn', {
    staticClass: "text-transform-none",
    attrs: {
      "loading": _vm.deleteFeedbackLoading,
      "color": "primary",
      "outlined": _vm.$vuetify.theme.dark
    },
    on: {
      "click": _vm.handleDelete
    }
  }, [_vm._v("Delete ")])], 1)], 1)], 1)], 1) : _c('stack', [_c('v-skeleton-loader', {
    attrs: {
      "type": "list-item-avatar"
    }
  }), _vm._l(2, function (index) {
    return _c('v-skeleton-loader', {
      key: index,
      attrs: {
        "type": "article"
      }
    });
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }