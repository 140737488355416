<template>
    <v-card v-if="activeFeedback" class="feedback-chat" outlined rounded>
      <div class="feedback-chat__header">
        <div class="feedback-chat__title">
          <span>Feedback #{{ activeFeedback.id }}</span>
        </div>
        <div
          :class="
            otherSessionId?.length > 0
              ? 'feedbackHeader'
              : 'feedback-chat__header-chips'
          "
          class="data-simplebar"
        >
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on">
                <span class="font-medium">{{
                  activeFeedback?.user?.identity
                }}</span>
              </v-chip>
            </template>
            <span> User Identity </span>
          </v-tooltip>
          <v-tooltip v-if="activeFeedback?.user?.name" bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" class="mx-2">
                <span class="font-medium">{{ activeFeedback.user.name }}</span>
              </v-chip>
            </template>
            <span> User name </span>
          </v-tooltip>
          <v-tooltip v-if="activeFeedback?.user?.email" bottom>
            <template v-slot:activator="{ on }">
              <v-chip v-on="on" class="mx-2">
                <span class="font-medium">{{ activeFeedback.user.email }}</span>
              </v-chip>
            </template>
            <span> User email </span>
          </v-tooltip>
          <v-chip
            v-if="activeFeedback.status === 1"
            color="#fff5dd"
            class="mx-2"
            text-color="orange"
          >
            <v-icon color="orange" left>mdi-clock-outline</v-icon>
            <span class="black--text font-medium">Not resolved</span>
          </v-chip>
          <v-chip
            v-if="activeFeedback.status === 2"
            color="#e0ffe1"
            class="mx-2"
            text-color="green"
          >
            <v-icon color="green" left>mdi-check-circle-outline</v-icon>
            <span class="black--text font-medium">Resolved</span>
          </v-chip>
          <template v-if="otherSessionId?.length">
            <template v-for="(id, index) in otherSessionId">
              <v-tooltip :key="index" bottom>
                <template #activator="{ on }">
                  <v-chip
                    color="#E3F2FD"
                    v-on="on"
                    text-color="primary"
                    class="mr-2"
                    @click="openSession(id)"
                  >
                    <v-icon color="primary" left>
                      mdi-timeline-text-outline
                    </v-icon>
                    {{ id }}
                  </v-chip>
                </template>
                <span> Connected Session </span>
              </v-tooltip>
            </template>
          </template>
        </div>
        <v-spacer />
        <div class="feedback-chat__controls">
          <v-menu bottom left>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon v-bind="attrs" v-on="on">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>
            <v-list style="padding: 0">
              <v-list-item
                ripple
                style="padding: 0 16px 0 12px"
                @click="copyLink"
              >
                <v-icon small>mdi-content-copy</v-icon>
                <v-list-item-title style="font-size: 12px"
                  >Copy link
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                ripple
                style="padding: 0 16px 0 12px"
                @click="changeFeedbackStatus(activeFeedback.status)"
              >
                <v-icon small>mdi-check</v-icon>
                <v-list-item-title style="font-size: 12px">
                  Mark as
                  {{
                    this.activeFeedback.status === 1 ? "Resolved" : "Not Resolved"
                  }}
                </v-list-item-title>
              </v-list-item>
              <v-list-item
                ripple
                style="padding: 0 16px 0 12px"
                @click="deleteFeedbackDialog = true"
              >
                <v-icon small>mdi-delete</v-icon>
                <v-list-item-title style="font-size: 12px">
                  Delete
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="feedback-chat__body">
        <v-alert outlined v-if="otherSessionId?.length" color="primary">
          <v-icon color="primary" left> mdi-timeline-text-outline </v-icon>
          This feedback is generated from multiple sessions...
        </v-alert>
        <div class="feedback-chat__messages">
          <div class="feedback-chat__message">
            <template v-if="!attachmentsBusy">
              <div class="feedback-chat__prepend">
                <div class="feedback-chat__prepend-img">
                  <v-icon color="primary" style="font-size: 20px"
                    >mdi-message-reply-text-outline
                  </v-icon>
                </div>
                <div class="feedback-chat__prepend-stripe"></div>
              </div>
              <div class="feedback-chat__attachment">
                <div
                  v-if="activeFeedback.message"
                  class="feedback-chat__attachment-message"
                  v-html="message"
                />
                <div
                  v-else
                  class="feedback-chat__attachment-message feedback-chat__attachment-message--empty"
                >
                  No message
                </div>
  
                <div class="feedback-chat__attachment-date darkGrey--text">
                  <date-tooltip :date="activeFeedback.created_at" />
                </div>
                <div
                  v-if="attachments?.length > 0"
                  class="feedback-chat__attachment-list"
                >
                  <feedback-attachment
                    v-for="(attachment, index) in attachments"
                    :key="index"
                    :attachment="attachment"
                    @onDelete="handleDeleteAttachment"
                    @onMediaClick="handleMediaClick"
                  />
                </div>
              </div>
            </template>
            <template v-else>
              <div class="feedback-chat__attachment-list">
                <feedback-attachment-skeleton
                  v-for="index in activeFeedback.stats.attachments"
                  :key="index"
                />
              </div>
            </template>
          </div>
        </div>
      </div>
  
      <v-dialog
        v-model="dialog"
        content-class="attachment-media-dialog"
        height="100%"
        scrollable
        width="auto"
      >
        <img
          v-if="activeMedia && activeMedia.mime.includes('image')"
          :src="activeMedia.url"
          :style="{
            aspectRatio: activeMedia.width / activeMedia.height,
          }"
          height="100%"
        />
        <video
          v-if="activeMedia && activeMedia.mime.includes('video')"
          :src="activeMedia.url"
          :style="{
            aspectRatio: activeMedia.width / activeMedia.height,
          }"
          controls
          height="100%"
        ></video>
      </v-dialog>
  
      <v-dialog v-model="deleteFeedbackDialog" width="500">
        <v-card>
          <v-card-title class="font-size-h3">Delete feedback</v-card-title>
  
          <v-divider />
  
          <v-card-text class="bodyFont p-2">
            Are you sure you want to delete this feedback?
            <v-alert class="mt-4" color="info" outlined>
              Attachment and feedback will be deleted for other sessions also.
            </v-alert>
          </v-card-text>
  
          <v-divider />
  
          <v-card-actions>
            <v-spacer />
            <v-btn
              class="text-transform-none"
              color="primary"
              text
              @click="deleteFeedbackDialog = false"
            >
              Cancel
            </v-btn>
            <v-btn
              :loading="deleteFeedbackLoading"
              color="primary"
              class="text-transform-none"
              :outlined="$vuetify.theme.dark"
              @click="handleDelete"
              >Delete
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card>
    <stack v-else>
      <v-skeleton-loader type="list-item-avatar"></v-skeleton-loader>
      <v-skeleton-loader
        v-for="index in 2"
        :key="index"
        type="article"
      ></v-skeleton-loader>
    </stack>
  </template>
  
  <script>
  import { GET_FEEDBACK_ATTACHMENT } from "@/store/releases/releases.module";
  import FeedbackAttachment from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/FeedbackAttachment/index.vue";
  import FeedbackAttachmentSkeleton from "@/view/components/Sessions/SessionDetailPage/SessionFeedback/FeedbackAttachment/FeedbackAttachmentSkeleton.vue";
  import { mapMutations, mapGetters, mapState } from "vuex";
  
  export default {
    components: { FeedbackAttachmentSkeleton, FeedbackAttachment },
    props: {
      activeFeedback: {
        type: Object,
        default: null,
      },
    },
    data() {
      return {
        deleteFeedbackDialog: false,
        attachmentsBusy: true,
        attachments: [],
        dialog: false,
        activeMedia: null,
        deleteFeedbackLoading: false,
      };
    },
    computed: {
      ...mapState({
        showSessionDetails: (state) => state.sessions.showSessionDetails,
      }),
      ...mapGetters({
        getCommentAttachmentSocketData: "getCommentAttachmentSocketData",
      }),
      otherSessionId() {
        return this.activeFeedback?.sessions?.filter(
          (session) => session !== this.$route.params.session_id
        );
      },
      message() {
        return this.activeFeedback.message.split("\n").join("<br>");
      },
    },
    methods: {
      ...mapMutations({
        toggleSessionDetails: "toggleSessionDetails",
      }),
      openSession(id) {
        this.$router.push({
          name: "sessions-detail",
          params: { app_id: this.$route.params.app_id, session_id: id },
        });
      },
      copyLink() {
        this.copyToClipboard(
          `${window.origin}${this.pageRoutes.app.feedbackDetails.url(
            this.$route.params.app_id,
            this.$route.params.session_id,
            this.activeFeedback.id
          )}`
        );
      },
      changeFeedbackStatus(currentStatus) {
        const status = currentStatus === 1 ? 2 : 1;
        this.$coreApi
          .put(`/session/feedback/${this.activeFeedback.id}/status`, {
            status,
          })
          .then(() => {
            this.$emit("onStatusChange", status);
          })
          .catch((error) => {
            this.notifyErrorMessage(
              error.message ?? "Failed to mark the feedback"
            );
          });
      },
      getFeedbackAttachments(feedbackId) {
        if (!feedbackId) return;
  
        this.attachmentsBusy = true;
  
        return this.$store
          .dispatch(GET_FEEDBACK_ATTACHMENT, {
            feedback_id: feedbackId,
          })
          .then(({ data }) => {
            this.attachments = data;
          })
          .catch((err) => {
            this.notifyErrorMessage(err.message);
          })
          .finally(() => {
            this.attachmentsBusy = false;
          });
      },
      handleMediaClick(media) {
        this.activeMedia = media;
      },
      async handleDelete() {
        try {
          this.deleteFeedbackLoading = true;
          const { data } = await this.$coreApi.delete(
            this.apiRoutes.core.session.feedbackById(this.activeFeedback.id)
          );
  
          this.EventBus.$emit("notification", {
            color: "success",
            text: data.message,
          });
          this.deleteFeedbackDialog = false;
          this.$emit("onDelete", this.activeFeedback.id);
        } catch (error) {
          this.notifyErrorMessage(error.message);
        } finally {
          this.deleteFeedbackLoading = false;
        }
      },
      async handleDeleteAttachment(attachmentId) {
        this.attachments = this.attachments.filter(
          (attachment) => attachment.id !== attachmentId
        );
        this.$emit("onAttachmentDelete", this.activeFeedback.id);
      },
    },
    watch: {
      getCommentAttachmentSocketData(val) {
        if (
          val.destination === "sdk_feedback" &&
          val.destination_id === this.activeFeedback.id
        ) {
          this.attachments = this.attachments.map((x) => {
            if (x.id === val.id) {
              x.status = val.status;
              x.file.url = val.url;
              x.file.thumbnail = val.thumbnail;
            }
            return x;
          });
        }
      },
      "activeFeedback.id"(value) {
        if (this.activeFeedback.stats.attachments > 0) {
          this.getFeedbackAttachments(value);
        } else {
          this.attachments = [];
          this.attachmentsBusy = false;
        }
      },
      activeMedia: function () {
        if (this.activeMedia) {
          this.dialog = true;
        }
      },
      dialog: function () {
        if (!this.dialog) {
          this.activeMedia = null;
        }
      },
    },
  };
  </script>
  
  <style lang="scss">
  .feedbackHeader {
    width: 75%;
    overflow-x: scroll;
    padding: 4px;
    margin-left: 5px;
    margin-top: 4px;
    white-space: nowrap;
    scrollbar-width: thin;
    scrollbar-color: #888888 #f0f0f0;
  }
  .feedbackHeader::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  .feedbackHeader::-webkit-scrollbar-track {
    background: white;
  }
  .feedbackHeader::-webkit-scrollbar-thumb {
    background-color: grey;
  }
  
  .feedback-chat {
    border-radius: 10px;
    display: flex;
    $padding: 16px;
    flex-direction: column;
    max-height: 592px;
    padding: 0;
  
    &__header {
      align-items: center;
      border-bottom: 1px solid #464e5f;
      display: flex;
      justify-content: space-between;
      padding: $padding;
  
      &-chips {
        align-items: center;
        display: flex;
        gap: 8px;
        margin-left: 12px;
      }
    }
  
    &__body {
      flex: 1;
      overflow: auto;
      padding: 24px $padding;
    }
  
    &__title {
      font-size: 16px;
      font-weight: 500;
      line-height: 1.5;
    }
  
    &__controls {
    }
  
    &__user {
      align-items: center;
      display: flex;
      gap: 8px;
  
      &-name {
        color: $grey;
      }
    }
  
    &__message {
      display: flex;
      gap: 24px;
      position: relative;
  
      & > *:last-child {
        flex: 1;
      }
    }
  
    &__message {
      &-header {
        align-items: center;
        display: flex;
        justify-content: space-between;
      }
  
      &-body {
        font-size: 14px;
        margin-top: 8px;
      }
  
      &-footer {
        margin-top: 16px;
      }
    }
  
    &__time {
      color: grey;
      font-size: 12px;
      letter-spacing: 0.4px;
    }
  
    @at-root {
      .v-dialog.attachment-media-dialog {
        height: 100% !important;
      }
    }
  
    &__prepend {
      align-items: center;
      display: flex;
      flex-direction: column;
      gap: 8px;
  
      &-img {
        align-items: center;
        @include size(36px);
        border-radius: 50%;
        display: flex;
        justify-content: center;
        overflow: hidden;
        position: relative;
  
        &:before {
          background: $blue;
          content: "";
          height: 100%;
          opacity: 0.06;
          position: absolute;
          width: 100%;
        }
      }
  
      &-stripe {
        background: $blue;
        flex: 1;
        opacity: 0.06;
        width: 4px;
      }
    }
  
    &__attachment-list {
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-top: 28px;
    }
  
    &__attachment {
      &-message {
        font-size: 14px;
        line-height: 1.42;
  
        &--empty {
          color: rgba(0, 0, 0, 0.6);
        }
      }
  
      &-date {
        color: rgba(0, 0, 0, 0.6);
        font-size: 12px;
        margin-top: 4px;
      }
    }
  }
  </style>
